import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Helmet from "../components/Helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import Team from "../components/Team";

function Landing() {
    return (
        <>
            <Helmet title={'Welcome to UB Healthy Project Inc.'}></Helmet>
            <Container fluid className="p-5 bg-body-tertiary">
                <Container className="p-md-5">
                    <h1 className="col-md-5">Welcome to <br />Uptown &amp; Boogie <br />Healthy Project</h1>
                    <p className="col-md-6">We expand access to fresh, nutritious food and micro mobility education within two New York City neighborhoods - Harlem and The Bronx. Our programming creates opportunities for low-income community residents in under-resourced areas to learn how to lead healthier lifestyles through food and fitness.</p>
                    <p>
                        <Button href="/contact/" size="lg" variant="dark text-white me-3">Get in Touch</Button>
                        <Button href="/about/" size="lg" variant="outline-secondary">Learn More</Button>
                    </p>
                </Container>
            </Container>

            <Container fluid className="text-center p-md-5 bg-warning">
                <Container className="col-md-6 p-md-5">
                    <h2>Mission</h2>
                    <p>To improve communities' physical and mental well-being through nutritional programming while supporting local producers and businesses and providing easily accessible micro-mobility education.</p>
                </Container>
            </Container>

            <Container fluid className="my-5">
                <Container className="col-md-6 p-md-5 text-center">
                    <h2>Current Programs</h2>
                    <p>Explore our diverse range of programs, including CSA memberships, Kids Club activities, Bronx Open Streets initiatives, and sustainable waste management practices at our farmers markets in Harlem and The Bronx, to discover how we're positively impacting your community.</p>
                </Container>

                <Container className="mb-5">
                    <Row xs={1} md={3} className="g-4 justify-content-center">
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={`${process.env.PUBLIC_URL}/assets/markets/ugf_fm.png`} />
                                <Card.Body>
                                    <Card.Title>Farmers Market</Card.Title>
                                    <Card.Text>Explore vibrant Harlem &amp; Bronx farmers markets! Fresh local produce, community spirit. Discover more now!</Card.Text>
                                    <p className="fw-medium"><a href="/farmersmarket/">Learn more <FontAwesomeIcon icon={faArrowRightLong} /></a></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={`${process.env.PUBLIC_URL}/assets/markets/csa.png`} />
                                <Card.Body>
                                    <Card.Title>Community Supported Agriculture</Card.Title>
                                    <Card.Text>Discover Harlem &amp; Bronx CSA: Fresh, Local, Sustainable Farming. Join Us Today!</Card.Text>
                                    <p className="fw-medium"><a href="/csa/">Learn more <FontAwesomeIcon icon={faArrowRightLong} /></a></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={`${process.env.PUBLIC_URL}/assets/markets/youth.png`} />
                                <Card.Body>
                                    <Card.Title>Kids Club at the Market</Card.Title>
                                    <Card.Text>Explore Kids Club at Harlem &amp; Bronx Farmers Markets for fun and educational experiences!</Card.Text>
                                    <p className="fw-medium"><a href="/kidsclub/">Learn more <FontAwesomeIcon icon={faArrowRightLong} /></a></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <Col>
                            <Card>
                                <Card.Img variant="top" src="https://picsum.photos/id/23/100/100" />
                                <Card.Body>
                                    <Card.Title>Bronx Open Streets</Card.Title>
                                    <Card.Text>Discover vibrant Bronx Open Streets - a community-driven initiative fostering connectivity and lively urban spaces.</Card.Text>
                                    <p className="fw-medium">Learn more <FontAwesomeIcon icon={faArrowRightLong} /></p>
                                </Card.Body>
                            </Card>
                        </Col> */}
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={`${process.env.PUBLIC_URL}/assets/markets/compost.png`} />
                                <Card.Body>
                                    <Card.Title>Composting</Card.Title>
                                    <Card.Text>Discover sustainable living in Bronx & Harlem markets: Waste reduction, compost drop-off, and clothes recycling for a greener future. </Card.Text>
                                    <p className="fw-medium"><a href="/composting/">Learn more <FontAwesomeIcon icon={faArrowRightLong} /></a></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={`${process.env.PUBLIC_URL}/assets/markets/clothingrecycle.png`} />
                                <Card.Body>
                                    <Card.Title>Textile Recycling</Card.Title>
                                    <Card.Text>Discover sustainable living in Bronx & Harlem markets: Waste reduction, compost drop-off, and clothes recycling for a greener future. </Card.Text>
                                    <p className="fw-medium"><a href="/textilerecycling/">Learn more <FontAwesomeIcon icon={faArrowRightLong} /></a></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={`${process.env.PUBLIC_URL}/assets/markets/seedling-tray.jpg`} />
                                <Card.Body>
                                    <Card.Title>Greenhouse</Card.Title>
                                    <Card.Text>A greenhouse which impacts the growth of an urban and rural farm land using natural resources and technology.</Card.Text>
                                    <p className="fw-medium"><a href="https://www.uptowngoodfood.com/greenhouse" target="_blank" rel="noreferrer">Learn more <FontAwesomeIcon icon={faArrowRightLong} /></a></p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* Meet our talented team Bubble */}
            <Team />
        </>
    )
}

export default Landing;