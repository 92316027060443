import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';

const Volunteer = () => {

    return (
        <>

            <Container fluid className="py-md-5 bg-light">
                <Container>
                    <Row className="align-items-center py-5 justify-content-center mx-auto">
                        <Col sm={12} md={6}>
                            <img className="img-fluid rounded-3" src={`${process.env.PUBLIC_URL}/assets/markets/volunteer.jpg`} loading="lazy" alt="Community Supported Agriculture (CSA)" />
                        </Col>
                        <Col sm={12} md={6}>
                            <h2>VOLUNTEERS</h2>
                            <p>Be part of our farmers market program's success story by becoming a volunteer and contributing to community-driven initiatives.</p>
                            <p>
                                <Button href="mailto:ubhp@ubhealthyproject.org" size="lg" variant="warning text-dark me-3">Get in Touch</Button>
                                <Button href="/contact/" size="lg" variant="outline-secondary">Learn More</Button>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Volunteer