import { Col, Container, Row } from "react-bootstrap";
import Helmet from "../components/Helmet";

function Composting() {
    return (
        <>
            <Helmet title={'Waste Reduction: Compost Drop-off Sites'}></Helmet>
            <Container fluid className="p-5 bg-body-tertiary">
                <Container className="text-center">
                    <h1>Composting</h1>
                    <p className="col-md-6 mb-5 mx-auto">With the success of our October 2022 4-week compost drop-off pilot at Bronx Park East Farmers Market, we recognize the need for accessible drop-off sites in The Bronx. We're thrilled to share that Uptown &amp; Boogie Healthy Project will provide from June to November 2024 a full-season food scrap drop-off site at Bronx Park East Farmers Market.</p>
                    <img className="img-fluid rounded-3" src={`${process.env.PUBLIC_URL}/assets/markets/compost.png`} loading="lazy" alt="Community Supported Agriculture (CSA)" />
                </Container>
            </Container>

            <Container fluid className="text-center p-md-5 mb-5 bg-warning">
                <Container>
                    <h2>Locations</h2>
                </Container>
            </Container>

            <Container className="mb-5">
                <Row>
                    <Col md={6}>
                        <h2>Harlem <br /><small>St Nicholas Miracle Garden</small></h2>
                        <p>Thursdays, June 6 - October / 4pm-7pm</p>
                        <address>330 St Nicholas Ave, New York 10027</address>
                        <h2>Harlem <br /><small>St Nicholas Park</small></h2>
                        <p>Saturdays, June 1 - November 23 / 9am-3pm</p>
                        <address>St Nicholas Ave &amp; W137th St., New York 10030</address>
                        <p>In 2018, I enrolled in the Snug Harbor Cultural Center &amp; Botanical Garden Master Composting Program where I learned how to build and maintain an outdoor compost system. As a final project, I started a DSNY organics collection program and was awarded the 2018 Community-Scale Composting Grant provided by the Citizens Committee for New York City for a 3-bin outdoor wooden compost system built at William B. Washington Garden - Harlem. </p>
                        <p>From 2018-2022, the garden offered 24/7 community drop-off organics collection via the NY Department of Sanitation organics program with collaboration from GrowNYC collected over 1200lbs of food scraps per week and processed 600lbs in the garden's outdoor compost system.</p>
                        <p>Now, the bin system process GrowNYC community drop-off collection and provides their staffers a "what happens after collection" education and hands-on experience.</p>
                    </Col>
                    <Col md={6}>
                        <h2>The Bronx <br /><small>Bronx Park East (BxPE) Market</small></h2>
                        <address>2045 Bronx Pk E, Bronx, NY 10462</address>
                        <p>Sundays, June - November / 9am-4pm</p>
                        <p>For the month of October, I piloted a food scraps drop-off site at Bronx Park East Farmers Market &amp; CSA hosted by Greenfeen OrganiX on Sundays using ioby crowdfunding platform as a grassroots program to collect and process in The Bronx.</p>
                        <p>We collected ~100lbs per week through localized promotion.</p>
                        <h2>Partnerships</h2>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="https://greenfeenorganix.com/" target="_blank" rel="noreferrer">GreenFeen OrganiX (GFO)</a></li>
                        </ul>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

export default Composting;
