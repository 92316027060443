import { Col, Container, Button, Row } from "react-bootstrap";
import Helmet from "../components/Helmet";

function ChefsRestaurants() {

    return (
        <>
            <Helmet title={'Chefs and Restaurants'}></Helmet>
            <Container fluid className="p-5 bg-body-tertiary">
                <Container className="p-md-5">
                    <h1 className="col-md-5">Chefs and Restaurants</h1>
                    <p className="col-md-6">At Uptown &amp; Boogie Healthy Project, we believe in the power of fresh, local ingredients. Our family-owned farm is dedicated to sustainable agricultural practices, bringing you the finest fruits and vegetables at their peak ripeness. Our commitment to quality ensures that every bite bursts with the authentic taste of the season.</p>
                </Container>
            </Container>

            <Container fluid className="p-0">

                <Container fluid>
                    <Row className="align-items-center">
                        <Col sm={12} md={6}>
                            <img className="img-fluid w-100 h-100" src={`${process.env.PUBLIC_URL}/assets/landing/chef.png`} loading="lazy" alt="Bronx Park East Farmers Market" />
                        </Col>
                        <Col sm={12} md={6} className="p-5">
                            <h2>Elevate Your Dishes with Our Bounty</h2>
                            <h3>A Harvest of Culinary Inspiration</h3>
                            <ul>
                                <li>
                                    Summer Symphony: Savor the sunshine with our juicy tomatoes, crisp cucumbers, flavorful peppers, and vibrant salad greens.
                                </li>
                                <li>
                                    Autumn's Abundance: Celebrate the fall harvest with crisp apples, sweet pears, winter squashes, and earthy root vegetables.
                                </li>
                                <li>
                                    Grown with Care: We take pride in offering organic and non-GMO produce, ensuring the highest quality and safety for your kitchen creations.
                                </li>
                            </ul>
                            <p className="text-center"><Button href="mailto:ubhp@ubhealthyproject.org" target="_blank" size="lg" variant="outline-secondary">Learn More</Button></p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container className="py-5">
                <div className="text-center">
                    <h2>Why Choose Uptown Good Food?</h2>
                    <h3>Your Trusted Partner for Peak Season Produce</h3>
                </div>
                <ul class="list-group list-group-horizontal-md">
                    <li class="list-group-item border-0">
                        Freshness Delivered: Enjoy consistent, reliable weekly deliveries throughout the season (June-November) so you can always count on having the freshest ingredients.
                    </li>
                    <li class="list-group-item border-0">
                        Menu-Focused Solutions: We work closely with chefs to understand your needs and preferences, ensuring our deliveries perfectly complement your menu.
                    </li>
                    <li class="list-group-item border-0">
                        Taste the Difference: Experience the superior quality and unmatched flavor that farm-fresh produce brings to your dishes.
                    </li>
                </ul>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="text-center p-0 pt-md-5">
                    <Container fluid className="p-5 bg-warning">
                        <h2>Community Supported Agriculture</h2>
                    </Container>

                    <Container fluid className="p-5 bg-white">
                        <p className="col-md-6 mb-5 mx-auto fs-4">Explore the abundance of fresh, seasonal produce with Community Supported Agriculture (CSA) programs in Harlem and The Bronx from June to October, fostering a connection between local farmers and the community.</p>
                        <p>
                            <Button href="/csa/" size="lg" variant="outline-secondary">Enroll Today</Button>
                        </p>
                        <img className="img-fluid col-md-5 p-5 rounded-3" src={`${process.env.PUBLIC_URL}/assets/markets/csa.png`} loading="lazy" alt="Community Supported Agriculture (CSA)" />
                    </Container>
                </Container>
            </Container>

        </>
    );
}

export default ChefsRestaurants;
