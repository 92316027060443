import React from "react";
import YouTube from "react-youtube";


class MovieClip extends React.Component {
    render() {
        const options = {
            // height: '400',
            // width: '640',
            playerVars: {
                autoplay: 1,
                controls: 1,
            },
        };

        return <YouTube videoId={this.props.vid} options={options} className='media-frame' onReady={this._onReady} id="video" />;
    }

    _onReady(event) {
        event.target.pauseVideo();
    }
}

export default MovieClip;