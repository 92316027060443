import { Container, Row, Col, Button } from "react-bootstrap";
import Helmet from "../components/Helmet";

function YouthPrograms() {
    return (
        <>
            <Helmet title={'Youth Programs'}></Helmet>
            <Container fluid className="p-5 bg-body-tertiary">
                <Container className="text-center">
                    <h1>Kids Club at the Market</h1>
                    <p className="col-md-6 mb-5 mx-auto">Welcome to our Kids Club program webpage! We are excited to introduce you to this empowering initiative encouraging children ages 4 to 13 to choose healthy food. The Kids Club is a <b>FREE</b> program held <b>from June to November</b>.</p>
                    <img className="img-fluid rounded-3" src={`${process.env.PUBLIC_URL}/assets/markets/youth.png`} loading="lazy" alt="Kids Clubs" />
                </Container>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="text-center p-0 pt-md-5">
                    <Container fluid className="p-5 bg-warning">
                        <h2>History</h2>
                    </Container>

                    <Container className="text-start my-5 bg-white">
                        <h3>3-Fold Mission</h3>
                        <Row>
                            <Col>Empower children to make healthy food choices</Col>
                            <Col>Strengthen and sustain healthy communities through supporting farmers and cultivating future farmers market supporters</Col>
                            <Col>Expand farmers markets from a retail location into a place where children can try new foods and learn about healthy eating.</Col>
                        </Row>
                    </Container>
                </Container>
            </Container>

            <Container className="my-5">
                <h3>Kids Club at the Market Program</h3>
                <Row>
                    <Col sm={12} md={6}><p>During the program, children who participate will receive <u> at least $2 in market currency (POP Tokens)</u> each week to spend on fresh fruits and vegetables at the market. Not only will they have the opportunity to choose nutritious and delicious produce, but they will also collect a stamp on their POP Passport at each visit. </p></Col>
                    <Col sm={12} md={6}><p>We believe every child should have access to fresh and nutritious food, so we are committed to making the kids program permanent across all our farm programs. To achieve this goal, we kindly ask for your support through a donation. Your contribution will directly impact the lives of children in our community, fostering a love for healthy eating and empowering them to make positive food choices.</p></Col>
                </Row>
                <p className="text-center pt-md-5">
                <img className="img-fluid rounded-3" src={`${process.env.PUBLIC_URL}/assets/markets/youthart.jpg`} loading="lazy" alt="Kids Clubs" />
                </p>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="text-center p-0 pt-md-5">
                    <Container fluid className="p-5 bg-warning">
                        <p className="col-md-3 mb-5 mx-auto fs-2">Together, we can create a healthier future for our youth!</p>
                        <p>
                            <Button href="https://airtable.com/embed/appKsXoyzcZyCegnL/pagsYwwNMvnE62SKM/form" size="lg" variant="light text-dark me-3" target="_blank">Enroll</Button>
                            <Button href={`${process.env.PUBLIC_URL}/assets/docs/POPClub-Parent-Information.pdf`} size="lg" rel="noopener noreferrer" target="_blank" variant="outline-secondary">Parent/Guardian Information</Button>
                        </p>
                    </Container>
                </Container>
            </Container>
        </>
    );
}

export default YouthPrograms;
