import React from 'react'
import { Container } from 'react-bootstrap';

const Helmet = (props) => {
    document.title = 'Uptown and Boogie Healthy Project | ' + props.title;

    return (
        <>
            <Container className='w-100'>
                {props.children}
            </Container>
        </>
    )
}

export default Helmet