import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlickr, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { Col, Container, Row, Button } from 'react-bootstrap';
import Volunteer from './Volunteer';
function Footer() {

    return (
        <>
            <Volunteer />

            <Container fluid className="text-center my-md-5 p-5 bg-light">
                <Container className="py-4">
                    <Row>
                        <Col sm={12} md={8}>
                            <h2>Want to support our mission?</h2>
                        </Col>
                        <Col sm={12} md={4}>
                            <Button href="https://donate.stripe.com/aEU6rG3USf24eo84gK" target="_blank" size="lg" variant="warning">Donate </Button>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container id='contact'>
                <Row className='d-flex justify-content-between'>
                    <Col sm={12} md={6}>
                        <p>&copy;{new Date().getFullYear()} Uptown &amp; Boogie Healthy Project, Inc. | All rights reserved.</p>
                        <p><small>BxPE Farmers Market &amp; CSA, Uptown Good Food, White Plains Road Farmers Market and Uptown &amp; Boogie Bicycle Advocacy are the brand and company's names of Uptown &amp; Boogie Healthy Project - 501(c)(3)</small></p>
                    </Col>
                    <Col sm={12} md={6}>
                        <p className='text-md-end'>Follow us: <a href="http://linkedin.com/company/ubhealthyproject" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a> <a href="https://flic.kr/s/aHsmVeDRFM" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFlickr} /></a> </p>
                        <ul className="list-unstyled text-md-end">
                            <li>New York, New York</li>
                            <li><a href="mailto:ubhp@ubhealthyproject.org">ubhp@ubhealthyproject.org</a></li>
                            <li><a href="tel:+1-347-391-7189">+1-347-391-7189</a></li>
                        </ul>
                    </Col>
                </Row>

            </Container>
        </>
    );
}
export default Footer;