import { Col, Container, Row } from "react-bootstrap";
import Helmet from "../components/Helmet";

function Benefits() {
    return (
        <>
            <Helmet title={'Farmers Market Nutritional Benefits'}></Helmet>
            <Container className="py-5 my-5">
                <h2 className="mb-md-4">Farmers Market Nutritional Benefits</h2>
                <h5>Information and Resources</h5>
            </Container>

            <Container className="my-5">
                <Row className="info-container justify-content-between align-items-center">
                    <Col sm={12} md={7} className="ps-md-5">
                        <div className="mt-5 my-3">
                            <h3>What is SNAP?</h3>
                            <p>The Supplemental Nutrition Assistance Program (SNAP) issues electronic benefits that can be used like cash to purchase food. SNAP helps low-income working people, senior citizens, the disabled and others feed their families.</p>

                        </div>
                        <div className="mt-5 my-3">
                            <h3>What is ACCESS HRA?</h3>
                            <p>The Human Resources Administration (HRA) ACCESS HRA website and free mobile app allow you to get information, apply for benefit programs, and view case information online.</p>
                            <p><a className="h6" href="https://a069-access.nyc.gov/accesshra/" rel="noopener noreferrer" target="_blank">Learn more <em className="text-success">ACCESS HRA</em></a></p>
                        </div>
                    </Col>
                    <Col sm={12} md={4} className="p-0">
                        <img className="img-fluid w-100" src="https://picsum.photos/id/493/500/300" loading="lazy" alt="6 various farm produce" />
                    </Col>
                </Row>
            </Container>

            <Container fluid className="bg-light bg-gradient py-5">
                <Container>
                    <Row className="info-container justify-content-center align-items-center">
                        <Col sm={12} md={4} className="p-0">
                            <img className="img-fluid w-100" src='https://picsum.photos/id/627/500/300' loading="lazy" alt="cart full of lettuce" />
                        </Col>
                        <Col sm={12} md={8}>

                            <div className="ps-5">
                                <h3>We Accept SNAP EBT Benefits</h3>
                                <p>The Bronx Park East CSA welcomes customers to use their SNAP/EBT benefits on seasonal shares at all locations.</p>
                                <Row>
                                    <Col md={6}>
                                        <h3>SNAP Eligible Food:</h3>
                                        <ul>
                                            <li>All fruits</li>
                                            <li>Meat, fish, poultry</li>
                                            <li>Cheese and all dairy products</li>
                                            <li>Eggs</li>
                                            <li>Baked goods, intended for offsite consumption</li>
                                            <li>Processed foods</li>
                                            <li>Maple/honey/cider/juices</li>
                                            <li>Herbs - plants, dried, bunched</li>
                                            <li>Plants and seedlings that bear food</li>
                                        </ul>
                                    </Col>
                                    <Col md={6}>
                                        <h3>Ineligible Products:</h3>
                                        <ul>
                                            <li>Non food products</li>
                                            <li>Wine &amp; alcohol products</li>
                                            <li>Hot foods</li>
                                            <li>Foods meant to be eaten on-site</li>
                                        </ul>
                                    </Col>
                                </Row>
                                <h3>What we do?</h3>
                                <ul>
                                    <li>We swipe your card at the welcome table</li>
                                    <li>We are going <strong>paperless</strong>! <em>We provide receipts via <strong>email and/or text message</strong>.</em></li>
                                    <li>Enjoy your farm fresh food!</li>
                                </ul>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container className="text-center my-5">
                <h2>Half Off Farm Box</h2>
                <p>New Yorkers who participate in <strong>Supplemental Nutrition Assistance Program (SNAP)</strong> <br/>receive a pre-assembled box of locally grown farm fresh vegetables for half off the price of our full box.</p>
                <div className="col-2 mx-auto bg-success text-white py-2 mb-3"><p className="fs-1 fw-bolder mb-0"><sup>$</sup>11</p></div>
            </Container>

        </>
    );
}

export default Benefits;
