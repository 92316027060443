import { Col, Container, Row, Button } from "react-bootstrap";
import Helmet from "../components/Helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faEnvelope, faInfoCircle, faMapMarkerAlt, faPhone, faStore } from "@fortawesome/free-solid-svg-icons";

function Contact() {
    return (
        <>
            <Helmet title={'Contact Us'}></Helmet>
            <Container fluid>
            <Container fluid className="p-5 bg-body-tertiary">
                <Container className="text-center">
                    <h1>Contact</h1>
                    <p className="col-md-6 mb-5 mx-auto">Contact our team for information on farmers markets, farm stands, CSA programs, community gardens, and youth initiatives in Harlem, Manhattan, and the Bronx. <br />Let's connect and grow together.</p>
                </Container>
            </Container>

                <Container>
                    <Row className="align-items-center pt-5 justify-content-center mx-auto text-center box-hover">
                        <Col className="p-md-5">
                            <FontAwesomeIcon icon={faStore} size="3x" />
                            <h4 className="my-3">Be a Vendor</h4>
                            <p>Apply now to vend at our farmers markets!</p>
                            <p>
                                <Button href="https://airtable.com/apptWMYTZ4msRriKR/pagOWx48AjQdPmSJz/form" size="lg" variant="warning">Apply</Button>&nbsp;&nbsp;&nbsp;
                                <Button href="/apply/" size="lg" variant="outline-secondary">Learn More</Button>
                            </p>
                        </Col>
                        <Col className="p-md-5">
                            <FontAwesomeIcon icon={faCalendarAlt} size="3x" />
                            <h3 className="my-3">Schedule an Appointment</h3>
                            <p>Let's connect and grow together.</p>
                            <p><Button href="https://calendar.app.google/1Tid4rtym5LpUDiK7" size="lg" variant="warning">Book</Button></p>
                        </Col>
                        <Col className="p-md-5">
                            <FontAwesomeIcon icon={faInfoCircle} size="3x" />
                            <h3 className="my-3">General Inquiries</h3>
                            <p>New York, New York</p>
                            <p><Button href="mailto:ubhp@ubhealthyproject.org" size="lg" variant="warning">Email </Button>&nbsp;&nbsp;&nbsp;
                                <Button href="tel:+1-347-391-7189" size="lg" variant="outline-dark">Phone</Button></p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container className="my-5 py-md-5">
                <Row className="justify-content-evenly">
                    <Col md={3}>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <FontAwesomeIcon icon={faEnvelope} size="3x" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                                Email:<br />
                                <a href="mailto:ubhp@ubhealthyproject.org">ubhp@ubhealthyproject.org</a>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className="border-start">
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 ps-5">
                                <FontAwesomeIcon icon={faPhone} size="3x" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                                Phone:<br />
                                <a href="tel:+1-347-391-7189">(347) 391-7189</a>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className="border-start">
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 ps-5">
                                <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                                Location:<br />
                                New York, New York
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Contact;
