import { Col, Container, Nav, Row, Tab, Button } from "react-bootstrap";
import Helmet from "../components/Helmet";

function JobOpportunities() {
    return (
        <>
            <Helmet title={'Job Opportunities'}></Helmet>
            <Container className="py-5 my-5">
                <h2 className="mb-md-4">Job Opportunities</h2>
                <p>Are you craving a flexible and rewarding work experience? Launch your career with our seasonal & temporary roles! We offer flexible hours, valuable skills development, a positive team environment, and potential for permanent positions. Join our team and make this season unforgettable! </p>
                <p>
                    <Button href="https://airtable.com/appHQWJ0KJcvj1Er0/shrrmpYvNHScDqc7I" target="_blank" size="lg" variant="warning text-dark me-3">Application</Button>
                    <Button href="/about/" size="lg" variant="outline-secondary">Learn More</Button>
                </p>
            </Container>

            <Container>
                <Tab.Container id="left-tabs-roles" defaultActiveKey="intern">
                    <h2 className="mb-md-4">Roles</h2>
                    <Row>
                        <Col sm={3}>
                            <Nav variant="underline" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="intern">Internship - Various Roles</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="gardener">Internship - Gardener <br />(Harlem, NY)</Nav.Link>
                                </Nav.Item>

                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="intern">
                                    <Container fluid>
                                        <p>We seek enthusiastic student interns to join our team for a hybrid role at the Harlem
                                            Community Garden. This internship offers a unique opportunity to gain hands-on
                                            experience in website development, event planning, youth engagement, content
                                            creation, and social media promotion.</p>
                                        <p><strong>Description:</strong></p>
                                        <p>We seek motivated student interns to join our dynamic team for a hybrid role at our
                                            farmers&#39; market and farm stand. This internship offers a unique opportunity to gain
                                            hands-on experience in marketing, event planning, web development, youth
                                            engagement, and community outreach.</p>
                                        <ul>
                                            <li>
                                                <strong>Website Designer:</strong>
                                                <ul>
                                                    <li>
                                                        Design a brand new website for the Harlem Community Garden.
                                                    </li>
                                                    <li>
                                                        Create a visually appealing and user-friendly online platform.
                                                    </li>
                                                    <li>
                                                        Incorporate features to showcase garden events, partners, and resources.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <strong>Website Developer and Software Engineer:</strong>
                                                <ul>
                                                    <li>
                                                        Code the website in React with Airtable API integration.
                                                    </li>
                                                    <li>
                                                        Develop functionalities to manage garden events and partners.
                                                    </li>
                                                    <li>
                                                        Ensure seamless navigation and user experience.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <strong>Youth Coordinator:</strong>
                                                <ul>
                                                    <li>
                                                        Develop a kids zone curriculum and organize events at the community garden.
                                                    </li>
                                                    <li>
                                                        Engage with young participants to promote environmental education and gardening
                                                        skills.
                                                    </li>
                                                    <li>
                                                        Create a fun and interactive environment for children to explore nature.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <strong>Social Media Coordinator:</strong>
                                                <ul>
                                                    <li>
                                                        Promote Open Garden Weekend and farm stand launch on social media platforms.
                                                    </li>
                                                    <li>
                                                        Create engaging content to increase community awareness and participation.
                                                    </li>
                                                    <li>
                                                        Monitor and respond to audience feedback and inquiries.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <strong>Farmers Market Assistant (3 positions):</strong>
                                            </li>
                                            <ul>
                                                <li>
                                                    Manage and take the lead for weekend farmers markets.
                                                </li>
                                                <li>
                                                    Assist vendors, set up stalls, and coordinate activities.
                                                </li>
                                                <li>
                                                    Provide excellent customer service and ensure a positive experience for visitors.
                                                </li>
                                            </ul>
                                        </ul>
                                        <p>
                                            <strong>Requirements:</strong>
                                        </p>
                                        <ul>
                                            <li>
                                                Currently enrolled in bootcamp, college or university.
                                            </li>
                                            <li>
                                                Passion for community engagement, urban agriculture, and environmental
                                                sustainability.
                                            </li>
                                            <li>
                                                Strong communication, organizational, and problem-solving skills.
                                            </li>
                                            <li>
                                                Ability to work independently and collaboratively in a team environment.
                                            </li>
                                            <li>
                                                Availability to work in-person on Saturdays in June, with flexibility for additional hours
                                                and events.
                                            </li>
                                        </ul>

                                        <p>
                                            <strong>Benefits:</strong>
                                        </p>
                                        <ul>
                                            <li>
                                                Hands-on experience in website development, event planning, youth engagement,
                                                content creation, and social media promotion.
                                            </li>
                                            <li>
                                                Mentorship and guidance from experienced professionals in urban agriculture and
                                                community development.
                                            </li>
                                            <li>
                                                Opportunity to positively impact local communities and contribute to the growth of the
                                                Harlem Community Garden.
                                            </li>
                                            <li>
                                                Potential for academic credit and professional development opportunities.
                                            </li>
                                        </ul>

                                        <p><strong>Deadline for Application:</strong> Saturday, June 1</p>
                                        <p>
                                            <Button href="https://airtable.com/appHQWJ0KJcvj1Er0/shrrmpYvNHScDqc7I" target="_blank" size="lg" variant="warning text-dark me-3">Apply</Button>
                                        </p>
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="gardener">
                                    <Container fluid>
                                        <p>We're seeking a dedicated and experienced seasonal gardener to join our team at a well-established (3+ years) vegetable and herb garden in Harlem, NY.</p>
                                        <p><strong>Reports to:</strong> Garden Manager, Executive or Operations Director</p>
                                        <p><strong>Role type:</strong> In-person</p>
                                        <p><strong>Days:</strong> </p>
                                        <ul>
                                            <li>
                                                Monday - Friday, May 13 - October 31 / 8:00 AM 5:00 PM at Harlem, NY
                                            </li>
                                            <li>
                                                Thursdays, June 6 - October 31 / 3:00 PM 7:00 PM at Saint Nicholas Miracle Garden (Harlem)
                                            </li>
                                        </ul>

                                        <p><strong>Our Garden:</strong> </p>
                                        <ul>
                                            <li>
                                                The garden promotes environmental awareness and serves as a growing educational resource and community gardening space. In addition, it addresses food insecurity by providing food access programming and workshops on healthy eating and sustainable lifestyles.
                                            </li>
                                            <li>
                                                Supplies fresh produce to farm stands in Harlem and a CSA program (Harlem &amp; Bronx) during peak season (June-October).
                                            </li>
                                        </ul>

                                        <p><strong>Job Responsibilities:</strong></p>
                                        <ul>
                                            <li>Planting &amp; Propagation: Seed and propagate seedlings in the greenhouse.</li>
                                            <li>Fieldwork: Prepare land, transplant seedlings, and provide essential plant care (irrigation, weeding, pruning, trellising, pest management).</li>
                                            <li>Harvesting &amp; Packing: Harvest, wash, and pack vegetables, herbs, and fruit.</li>
                                            <li>CSA Deliveries: Occasionally fill in as a delivery driver for the CSA program in Manhattan and The Bronx.</li>
                                            <li>Garden Maintenance: Keep all farm areas neat, clean, and organized.</li>
                                        </ul>

                                        <p><strong>The Ideal Candidate:</strong></p>
                                        <ul>
                                            <li>1-5 years of experience working on a small-scale vegetable farm using regenerative and organic practices.</li>
                                            <li>Strong work ethic, self-driven, and able to work independently and collaboratively.</li>
                                            <li>Excellent communication and interpersonal skills.</li>
                                            <li>Physically able to perform physically demanding tasks outdoors in all weather conditions.</li>
                                            <li>Some weekend availability is required.</li>
                                        </ul>
                                        <p><strong>Deadline for Application:</strong> Saturday, June 1</p>
                                        <p>
                                            <Button href="https://airtable.com/appHQWJ0KJcvj1Er0/shrrmpYvNHScDqc7I" target="_blank" size="lg" variant="warning text-dark me-3">Apply</Button>
                                        </p>

                                        <p><strong>In-person Start Date:</strong> </p>
                                        <ul>
                                            <li>
                                                Harlem, New York: Monday, May 13, 2024
                                            </li>
                                        </ul>
                                    </Container>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    );
}

export default JobOpportunities;
