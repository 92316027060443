import { Button, Col, Container, Row, Table } from "react-bootstrap";
import Helmet from "../components/Helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function Support() {
    return (
        <>
            <Helmet title={'Donate & Sponsorship'}></Helmet>
            <Container fluid className="p-5 bg-body-tertiary">
                <Container className="p-md-5">
                    <h1 className="col-md-5">Support Community Projects</h1>
                    <h2>Your support has an effect</h2>
                    <p className="col-md-6">Supporting our programs are not only appreciated, it is also tax deductible, which means you can receive a tax-deduction for your generous contribution.</p>
                </Container>
                <Container>
                    <p className="lead">Your donation will help make a real difference in the lives of others. <br />Thank you for helping to make the world a better place.</p>

                    <Row className="d-md-flex justify-content-evenly align-self-stretch py-0">
                        <Col sm={12} md={5} className="bg-light p-3">
                            <h5>Increase your impact with monthly contributions.</h5>
                            <Button href="https://donate.stripe.com/aEU6rG3USf24eo84gK" target="_blank" size="lg" variant="warning">Donate Monthly</Button>
                        </Col>
                        <Col sm={12} md={5} className="bg-light p-3">
                            <h5>Make a one-time contribution today!</h5>
                            <Button href="https://donate.stripe.com/aEU6rG3USf24eo84gK" target="_blank" size="lg" variant="outline-dark">Donate Today!</Button>
                        </Col>
                    </Row>
                </Container>

            </Container>

            <Container fluid className="p-0">
                <Container fluid className="p-0 pt-md-5">
                    <Container fluid className="p-5 text-center bg-warning mb-5">
                        <h2>Your donation helps build a more vital, healthier, and sustainable New York City.</h2>
                    </Container>
                    <Container>
                        <Row>
                            <Col sm={12} md={6}>
                                <h5 className="display-6">Bike Programming</h5>
                                <p>Contributing to our bike programming is an excellent way to support a healthy and sustainable form of transportation for the community. Biking provides environmental benefits such as reducing air pollution and stimulating the economy by creating jobs in the cycling industry. It also promotes physical health, as it is an active form of transportation. Additionally, biking is an affordable form of transportation that allows New Yorkers to get to their destination quickly and efficiently.</p>
                            </Col>
                            <Col sm={12} md={6}>
                                <h5 className="display-6">Farmers Market and Farm Stand</h5>
                                <p>Contributing to our food access programs supports local farmers and helps to bring fresh, locally grown produce to communities like the Bronx and Harlem. Our farm program supports the local economy and provides access to healthy and nutritious food options, which can improve community health.</p>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Container>

            <Container className="my-md-5">
                <h2 className="display-6 text-center mb-4">Sponsorship Opportunities</h2>
                <p>Thank you for considering becoming a sponsor for our Farmers Market and Farm Stand and the exciting youth activities in our community. Your generous support helps us create a vibrant and sustainable local environment while fostering educational opportunities for the younger generation. Explore the sponsorship levels below and choose the one that aligns with your commitment to community engagement and sustainable living.</p>
                <Table responsive="md" className="text-center">
                    <thead>
                        <tr>
                            <th className="col-md-4"></th>
                            <th className="col">Bronze Seed Sower</th>
                            <th className="col">Silver Crop Advocate</th>
                            <th className="col">Gold Farm Champion</th>
                            <th className="col">Platinum Harvest Partner</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row" class="text-start">Host a Corporate Volunteering Day on Farmland
                                <p className="fw-normal">Offers your organization the chance to unite employees in a meaningful experience of teamwork and community engagement while contributing to sustainable agriculture practices.</p>
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-start">Exclusive Naming Rights: Farmers Market and Youth Activity Program named after your organization.
                                <p className="fw-normal">Your organization's name will be displayed at the prestigious Farmers Market and Youth Activity Program, ensuring prominent recognition.</p>
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-start">Brand Visibility
                                <p className="fw-normal">Your organization's name and logo are prominently displayed on banners, sandwich boards, recipe cards, and salad bowls throughout the market, reaching a broad audience.</p>
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-start">Branded Tote Bag
                                <p className="fw-normal">Your logo is featured on a branded tote bag distributed to market attendees, providing ongoing visibility beyond the event.</p>
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-start">Market Map and Poster
                                <p className="fw-normal">Your logo is prominently featured on the printed market map and promotional poster, guiding visitors and enhancing brand recognition.</p>
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-start">Media Exposure
                                <p className="fw-normal">Regular mentions in earned media segments, maximizing exposure and enhancing your brand's reputation.</p>
                            </th>
                            <td></td>
                            <td></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-start">Activity Hosting Opportunity
                                <p className="fw-normal">This is an exclusive chance to host an engaging activity during the market, fostering community engagement and brand interaction.</p>
                            </th>
                            <td></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-start">Youth Workshop Sponsorship
                                <p className="fw-normal">Support and recognition for a dedicated youth workshop, investing in the development of future generations.</p>
                            </th>
                            <td></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-start">Compost Project Sponsorship
                                <p className="fw-normal">Prominent acknowledgment for supporting an environmentally sustainable initiative, demonstrating your commitment to eco-conscious practices.</p>
                            </th>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-start">Website Recognition
                                <p className="fw-normal">Your logo is displayed as a link to your website on the market's official website, driving traffic and enhancing online visibility.</p>
                            </th>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-start">Social Media Marketing
                                <p className="fw-normal">Regular mentions across the market's social media platforms, reaching a diverse audience and amplifying your brand's message.</p>
                            </th>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-end text-success">Cost for sponsorship tiers</th>
                            <td>$1,000</td>
                            <td>$2,500</td>
                            <td>$5,000 </td>
                            <td>$10,000+</td>
                        </tr>
                    </tbody>
                </Table>
                <p>If you need a different level than our existing levels, we will tailor one to fit your company perfectly! Let's explore other contributions like volunteering, supplies, and materials.</p>
                <p>We appreciate your commitment to supporting our agriculture and environmental sustainability and empowering the youth in our community!</p>
                <p className="text-center">
                    <Button href="https://donate.stripe.com/aEU6rG3USf24eo84gK" size="lg" variant="warning text-dark me-3">Donate</Button>
                    <Button href="mailto:ubhp@ubhealthyproject.org" size="lg" variant="outline-secondary text-dark me-3">Learn More</Button>
                </p>
            </Container>

        </>
    );
}

export default Support;
