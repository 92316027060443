import logo from "../img/logo.png";

import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";

function NavBarComponent() {

    const openNavDropDown = (e) => {
        // console.log('over', e);
        e.target.click()
    }
    // const openNavDropDownHref = (e) => {
        // console.log('onSelect ', e);
        // e.target.click()
    // }
    return (
        <>
            {/* {['md'].map((expand) => (
                <Navbar fixed="top" key={expand} bg="light" expand={expand} className="mb-3">
                    <Container fluid>
                        <Navbar.Brand href="/"><img
                            src={logo}
                            className="d-inline-block align-top"
                            alt="Uptown & Boogie Healthy Project Logo"
                        /></Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    UB Healthy Project
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Link href="/">Home</Nav.Link>
                                    <Nav.Link href="/about/">About</Nav.Link>
                                    <Nav.Link href="/team/">Our Team</Nav.Link>
                                    <Nav.Link href="/farmersmarket/">Farmers Markets</Nav.Link>
                                    <Nav.Link href="/donate/">Donate</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))} */}
            <Navbar collapseOnSelect expand="lg" className="px-5 py-3">
                <Container fluid>
                    <Navbar.Brand href="/"><img
                        src={logo}
                        className="d-inline-block align-top"
                        alt="Uptown & Boogie Healthy Project Logo"
                    /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mx-auto">
                            <Nav.Link href="/about/">About Us</Nav.Link>
                            <NavDropdown title="Programs" onMouseEnter={openNavDropDown} to="/cooking/" id="navbarScrollingDropdown1">
                                <NavDropdown.Item href="/programs/">
                                    Harvest Hub
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/farmersmarket/">
                                    Farmers Market &amp; Stand
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/cooking/">
                                    Chefs &amp; Restaurants
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/csa/">
                                    Community Supported Agriculture (CSA)
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/kidsclub/">
                                    Kids Club at the Market
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="https://ubbicycle.org/" target="_blank">
                                    Bicycle Advocacy
                                </NavDropdown.Item>
                                {/* <NavDropdown.Item href="/openstreets/">
                                    Bronx DOT Open Streets
                                </NavDropdown.Item> */}
                            </NavDropdown>
                            <NavDropdown title="Apply" onMouseEnter={openNavDropDown} id="navbarScrollingDropdown2">
                                <NavDropdown.Item href="/apply/">
                                    Farmers Market
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/hiring/">
                                    Hiring
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/board/">
                                    Board Opportunities
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Waste Reduction" onMouseEnter={openNavDropDown} id="navbarScrollingDropdown3">
                                <NavDropdown.Item href="/composting/">
                                    Composting
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/textilerecycling/">
                                    Textile Recycling
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/contact/">Contact</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link className="btn btn-dark bg-warning" href="/donate/">Donate &amp; Sponsorship</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}
export default NavBarComponent;