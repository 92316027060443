import { Col, Container, Nav, Row, Tab, Button } from "react-bootstrap";
import Helmet from "../components/Helmet";

function BoardOpportunities() {
    return (
        <>
            <Helmet title={'Job Opportunities'}></Helmet>
            <Container className="py-5 my-5">
                <h2 className="mb-md-4">Board Opportunities</h2>
                <p>The Uptown & Boogie Healthy Project seeks a dedicated and detail-oriented individuals to join our Board of Directors. We are a non-profit organization dedicated to supporting local farmers by providing opportunities for them to sell their products directly to consumers through farmers' markets, farm stands, and wholesale channels.</p>
                <p>
                    <Button href="https://airtable.com/appHQWJ0KJcvj1Er0/shrJMSOcxgfibhWCb" target="_blank" size="lg" variant="warning text-dark me-3">Application</Button>
                    <Button href="/about/" size="lg" variant="outline-secondary">Learn More</Button>
                </p>
            </Container>

            <Container>
                <Tab.Container id="left-tabs-roles" defaultActiveKey="treasurer">
                    <h2 className="mb-md-4">Board Members</h2>
                    <Row>
                        <Col sm={3}>
                            <Nav variant="underline" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="treasurer">Treasurer</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="vicepresident">General Board Member</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="treasurer">
                                    <Container fluid>
                                        <p><strong>Responsibilities:</strong></p>
                                        <ul>
                                            <li>
                                                Overseeing the organization's financial health and records.
                                            </li>
                                            <li>
                                                Working closely with the Executive Director to develop and maintain a budget.
                                            </li>
                                            <li>
                                                Managing bank accounts, accounts payable and receivable, and financial transactions.
                                            </li>
                                            <li>
                                                Preparing financial reports, including monthly and annual statements, for the board and tax purposes.
                                            </li>
                                            <li>
                                                Ensuring the organization's compliance with all relevant tax regulations.
                                            </li>
                                            <li>
                                                Working with the board to develop long-term financial plans.
                                            </li>
                                        </ul>
                                        <p><strong>Qualifications:</strong></p>
                                        <ul>
                                            <li>
                                                Prior experience as a treasurer, accountant, or other financial role is highly preferred.
                                            </li>
                                            <li>
                                                Strong understanding of accounting principles and non-profit financial management.
                                            </li>
                                            <li>
                                                Excellent analytical and problem-solving skills.
                                            </li>
                                            <li>
                                                Proficiency in financial software (e.g., QuickBooks).
                                            </li>
                                            <li>
                                                Ability to work independently and meet deadlines.
                                            </li>
                                            <li>
                                                A passion for local agriculture and the mission of the Uptown & Boogie Healthy Project.
                                            </li>
                                        </ul>

                                        <p><strong>Benefits:</strong></p>
                                        <ul>
                                            <li>
                                                The opportunity to positively impact your community and support local farmers.
                                            </li>
                                            <li>
                                                Working with a dedicated and passionate Board of Directors.
                                            </li>
                                            <li>
                                                The satisfaction of contributing to the financial health of a growing organization.
                                            </li>
                                        </ul>
                                        <Container className="mt-3 text-center">
                                            <p><strong>Deadline for Application:</strong> Wednesday, December 18</p>
                                            <p>
                                                <Button href="https://airtable.com/appHQWJ0KJcvj1Er0/shrJMSOcxgfibhWCb" target="_blank" size="lg" variant="warning text-dark me-3">Apply</Button>
                                            </p>
                                            <p><strong>We are an equal opportunity employer and value diversity in our organization.</strong></p>
                                        </Container>
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="vicepresident">
                                    <Container fluid>
                                        <p><strong>Responsibilities:</strong></p>
                                        <ul>
                                            <li>
                                                Provide strategic direction and leadership to the Board to support the organization's mission.
                                            </li>
                                            <li>
                                                Presided at board meetings in the President's absence and served as the chief volunteer officer.
                                            </li>
                                            <li>
                                                Work collaboratively with the President and Executive Director to develop and implement long-term goals.
                                            </li>
                                            <li>
                                                Oversee key committees and delegate tasks effectively.
                                            </li>
                                            <li>
                                                Represent the organization at community events and industry gatherings.
                                            </li>
                                            <li>
                                                Champion fundraising initiatives and cultivate relationships with donors and sponsors.
                                            </li>
                                            <li>
                                                Advocate for policies that support local agriculture and food systems.
                                            </li>
                                            <li>
                                                Assist the President in carrying out special projects when necessary.
                                            </li>
                                            <li>
                                                Serve on the executive committee if the board has such a committee.
                                            </li>
                                        </ul>
                                        <p><strong>Qualifications:</strong></p>
                                        <ul>
                                            <li>
                                                Proven leadership experience in a non-profit, business, or government setting.
                                            </li>
                                            <li>
                                                Strong understanding of the challenges and opportunities facing local agriculture.
                                            </li>
                                            <li>
                                                Excellent communication, interpersonal, and relationship-building skills.
                                            </li>
                                            <li>
                                                Strategic thinking and problem-solving abilities.
                                            </li>
                                            <li>
                                                Ability to delegate effectively and motivate others.
                                            </li>
                                            <li>
                                                A deep commitment to the mission and vision of the Uptown & Boogie Healthy Project.
                                            </li>
                                        </ul>

                                        <p><strong>Time Commitment:</strong></p>
                                        <ul>
                                            <li>
                                                Board meetings are held quarterly, with additional committee work as needed.
                                            </li>
                                            <li>
                                                Active participation in fundraising events and community outreach is a core responsibility. The ideal candidate will be a passionate advocate for our mission and comfortable engaging with various stakeholders.
                                            </li>
                                        </ul>

                                        <p><strong>Benefits:</strong></p>
                                        <ul>
                                            <li>
                                                The opportunity to shape the future of local agriculture in your community.
                                            </li>
                                            <li>
                                                Working alongside a passionate and dedicated Board of Directors.
                                            </li>
                                            <li>
                                                Making a real difference in the lives of local farmers and consumers.
                                            </li>
                                        </ul>

                                        <Container className="mt-3 text-center">
                                            <p><strong>Deadline for Application:</strong> Wednesday, December 18</p>
                                            <p>
                                                <Button href="https://airtable.com/appHQWJ0KJcvj1Er0/shrJMSOcxgfibhWCb" target="_blank" size="lg" variant="warning text-dark me-3">Apply</Button>
                                            </p>
                                            <p><strong>We are an equal opportunity employer and value diversity in our organization.</strong></p>
                                        </Container>
                                    </Container>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    );
}

export default BoardOpportunities;
