import { Container } from 'react-bootstrap';
import Helmet from '../components/Helmet';

function NotFound() {
    return (
        <>
            <Helmet title={'404 Not Found'}></Helmet>
            <Container fluid className="p-5 my-5 hero-banner text-center text-white">
                <h1>404 NOT FOUND</h1>
            </Container>
            <Container className="text-center p-5">
                <h2>Oops!</h2>
                <p>Sorry, an error has occurred, Requested page not found!</p>
                <p><a href='/'>Take me home</a></p>
            </Container>
        </>
    )
}
export default NotFound;