import { Col, Container, Row } from "react-bootstrap";
import Helmet from "../components/Helmet";

function TextileRecycling() {
    return (
        <>
            <Helmet title={'Waste Reduction: Clothing Recycle'}></Helmet>
            <Container fluid className="p-5 bg-body-tertiary">
                <Container className="text-center">
                    <h1>Textile Recycling</h1>
                    <p className="col-md-6 mx-auto">We have partnered with Green Tree Textile Recycling to further our commitment to environmental preservation. Their recycling services support individuals facing disadvantages and those in transitional periods. Green Tree aims to safeguard the environment by repurposing clothing and textiles for reuse by environmentally conscious manufacturers and designers.</p>
                    <p className="col-md-6 mb-5 mx-auto">Through our collaboration with Green Tree Textile Recycling, we endeavor to extend the lifespan of clothing and reduce textile waste. This initiative aligns with our mission to promote sustainable practices and contribute to a circular economy.</p>
                    <img className="img-fluid rounded-3" src={`${process.env.PUBLIC_URL}/assets/markets/clothingrecycle.png`} loading="lazy" alt="Clothing Reuse &amp; Recycling" />
                </Container>
            </Container>

            <Container fluid className="text-center p-md-5 mb-5 bg-warning">
                <Container>
                    <h2>Locations</h2>
                </Container>
            </Container>

            <Container className="mb-5">
                <Row>
                    <Col md={4}>
                        <h2>Harlem <br /><small>Uptown Good Food Farmers Market</small></h2>
                        <p>Saturdays, June 1 - November 23 / 9am-3pm</p>
                        <address>St Nicholas Ave &amp; West 137th Street, New York 10030</address>
                    </Col>
                    <Col md={4}>
                        <h2>The Bronx <br /><small>White Plains Road Market</small></h2>
                        <p>Saturdays, June 1 - November 23 / 9am-4pm</p>
                        <address>3500 White Plains Rd, Bronx, NY 10467</address>
                    </Col>
                    <Col md={4}>
                        <h2>The Bronx <br /><small>BxPE Market</small></h2>
                        <p>Sundays, June 2 - November 24 / 9am-4pm</p>
                        <address>2045 Bronx Pk E, Bronx, NY 10462</address>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default TextileRecycling;
