import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';

const Markets = () => {

    return (
        <>
               <Container className='my-5'>
                    <Row>
                        <Col md={6}>
                            <h2 className="fw-normal lh-1">Uptown Good Food</h2>
                            <ul className="list-unstyled">
                                <li><h3><a href="https://uptowngoodfood.com/csa-enrollment" target="_blank" rel="noreferrer">Farm Stand &amp; CSA</a></h3>
                                    Every Thursdays from June 6th to October 31st / 4pm to 7pm<br />
                                    St Nicholas Miracle Garden, 330 St Nicholas Ave, New York, NY 10027</li>
                            </ul>
                            <p>
                                <Button href="https://uptowngoodfood.com/" size="lg" variant="outline-secondary">Market Site</Button>
                            </p>
                        </Col>
                        <Col md={6}>
                            <h2 className="fw-normal lh-1">Bronx Park East (BxPE)</h2>
                            <ul className="list-unstyled">
                                <li><h3><a href="https://bxpemarket.com" target="_blank" rel="noreferrer">Farmers Market</a></h3>
                                    Sundays, June 2nd - November 24th / 9am - 4pm<br />
                                    Bronx Park / Brady Playground, 2045 Bronx Pk E, Bronx, NY 10462</li>
                            </ul>
                            <p>
                                <Button href="https://bxpemarket.com/" size="lg" variant="outline-secondary">Market Site</Button>
                            </p>
                        </Col>
                    </Row>
                </Container>
        </>
    )
}

export default Markets