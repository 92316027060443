import React, { useState } from 'react';

import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Helmet from "../components/Helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import Guideline from "../components/Guideline";

function MarketApplication() {

    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <Helmet title={'Farmers Market Vendor Application'}></Helmet>
            <Container fluid className="p-5 bg-body-tertiary">
                <Container className="text-center">
                    <h1>Become a Vendor</h1>
                    <p className="col-md-6 mb-5 mx-auto">Currently accepting applications for our Harlem and The Bronx farmers markets</p>
                    <img className="img-fluid w-100 rounded-3" src={`${process.env.PUBLIC_URL}/assets/markets/vendors.png`} loading="lazy" alt="Community Supported Agriculture (CSA)" />
                </Container>
            </Container>

            <Container>
                <Container className='mt-5'>
                    <Row>
                        <Col md={4}>
                            <h2 className="fw-normal lh-1">Uptown Good Food</h2>
                            <ul className="list-unstyled">
                                <li><h3><a href="https://uptowngoodfood.com/farmers-market" target="_blank" rel="noreferrer">Farmers Market</a></h3>
                                    Every Saturday from June 1st to August 31st / 9am to 3pm<br />
                                    St Nicholas Park, St Nicholas Ave St Nicholas Ave &amp;, W 137th St, New York, NY 10030</li>
                            </ul>
                            <p>
                                <a href='https://uptowngoodfood.com/' target='_blank' rel="noreferrer">Market Site <FontAwesomeIcon icon={faArrowRightLong} /></a>
                            </p>
                        </Col>
                        <Col md={4}>
                            <h2 className="fw-normal lh-1">Bronx Park East (BxPE)</h2>
                            <ul className="list-unstyled">
                                <li><h3><a href="https://bxpemarket.com" target="_blank" rel="noreferrer">Farmers Market</a></h3>
                                    Sundays, June 2nd - November 24th / 9am - 4pm<br />
                                    Bronx Park / Brady Playground, 2045 Bronx Pk E, Bronx, NY 10462</li>
                            </ul>
                            <p>
                                <a href='https://bxpemarket.com/' target='_blank' rel="noreferrer">Market Site <FontAwesomeIcon icon={faArrowRightLong} /></a>
                            </p>
                        </Col>
                        <Col md={4}>
                            <h2 className="fw-normal lh-1">White Plains Road</h2>
                            <ul className="list-unstyled">
                                <li><h3>Farmers Market</h3>
                                    Every Saturday from June 1st to August 31st / 9am to 4pm<br />
                                    Williamsbridge Square, White Plains Road, E211 St &amp;, E 212 St, Bronx, NY</li>
                            </ul>
                            <p>
                                {/* <Button href="https://uptowngoodfood.com/" size="lg" variant="outline-secondary">Market Site</Button> */}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="text-center p-0 pt-md-5">
                    <Container fluid className="p-5 bg-warning">
                        <h2>Apply to be a Farmer or Gardener</h2>
                    </Container>

                    <Container className="p-5 bg-white">
                        <h4>Gardener Fee: $25 <br />Farmer Fee: $40</h4>
                        <Row sm={1} md={4} className='mt-2 g-4 justify-content-evenly'>
                            <Col sm={12}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='fs-2'>1</Card.Title>
                                        <Card.Subtitle>Crop Plan Application</Card.Subtitle>
                                        <Card.Text>All Farmers and Gardeners MUST attach 2024 Farmers' Market Nutrition Program (FMNP)</Card.Text>
                                        <Card.Link href='https://agriculture.ny.gov/system/files/documents/2024/01/fmc-12cropplan_0.pdf' target='_blank' rel="noreferrer">(FMC-12) Application <FontAwesomeIcon icon={faArrowRightLong} /></Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='fs-2'>2</Card.Title>
                                        <Card.Subtitle>Read and Sign Agreement</Card.Subtitle>
                                        <Card.Text>Review the Uptown &amp; Boogie Healthy Project Rules and Regulations and Vendor Agreement before signing and submitting this application.</Card.Text>
                                        <Card.Link href={`${process.env.PUBLIC_URL}/assets/docs/Rules_and_Regulations_and_Vendor_Agreement.pdf`} target='_blank'>Form <FontAwesomeIcon icon={faArrowRightLong} /></Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='fs-2'>3</Card.Title>
                                        <Card.Subtitle>Apply</Card.Subtitle>
                                        <Card.Text>Ready to become a vendor! Uploading required documents now.</Card.Text>
                                        <Card.Link href='https://airtable.com/apptWMYTZ4msRriKR/pagErmkSl3enwLByy/form' target='_blank'>Application <FontAwesomeIcon icon={faArrowRightLong} /></Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="text-center p-0 pt-md-5">
                    <Container fluid className="p-5 bg-warning">
                        <h2>Apply to be a Vendor</h2>
                    </Container>

                    <Container className="p-5 bg-white">
                        <h4>Vendor Fee: $30</h4>
                        <Row sm={1} md={4} className='mt-2 g-4 justify-content-evenly'>
                            <Col sm={12}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='fs-2'>1</Card.Title>
                                        <Card.Subtitle>Obtain the Right License</Card.Subtitle>
                                        <Card.Text>Before participating as a vendor at the Uptown & Boogie Healthy Project farmers' markets, it is essential to obtain the required license. For understanding license and permit guidelines, the following button can serve as valuable resources.</Card.Text>
                                        <Button size="md" variant="" onClick={() => setModalShow(true)}>
                                            License Guidelines <FontAwesomeIcon icon={faArrowRightLong} />
                                        </Button>
                                        <Guideline show={modalShow} dialogClassName='modal-90w-lg' onHide={() => setModalShow(false)} />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='fs-2'>2</Card.Title>
                                        <Card.Subtitle>Read and Sign Agreement</Card.Subtitle>
                                        <Card.Text>Review the Uptown &amp; Boogie Healthy Project Rules and Regulations and Vendor Agreement before signing and submitting this application.</Card.Text>
                                        <Card.Link href={`${process.env.PUBLIC_URL}/assets/docs/Rules_and_Regulations_and_Vendor_Agreement.pdf`} target='_blank' rel="noreferrer">Form <FontAwesomeIcon icon={faArrowRightLong} /></Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='fs-2'>3</Card.Title>
                                        <Card.Subtitle>Attach Product List</Card.Subtitle>
                                        <Card.Text>All Vendors MUST attach their Product List</Card.Text>
                                        <Card.Link href={`${process.env.PUBLIC_URL}/assets/docs/Market_Product_Specifications.pdf`} target='_blank' rel="noreferrer">Market Product <FontAwesomeIcon icon={faArrowRightLong} /></Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='fs-2'>4</Card.Title>
                                        <Card.Subtitle>Apply</Card.Subtitle>
                                        <Card.Text>Ready to become a vendor! Uploading required documents now.</Card.Text>
                                        <Card.Link href='https://airtable.com/apptWMYTZ4msRriKR/pagErmkSl3enwLByy/form' target='_blank'>Application <FontAwesomeIcon icon={faArrowRightLong} /></Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="text-center p-0 pt-md-5">
                    <Container fluid className="p-5 bg-warning">
                        <h2>Wholesalers</h2>
                    </Container>

                    <Container className="p-5 bg-white">
                        <Row sm={1} md={4} className='mt-2 g-4 justify-content-evenly'>
                            <Col sm={12}>
                                <h2 className="fw-normal lh-1">Uptown Good Food</h2>
                                <ul className="list-unstyled">
                                    <li><h3><a href="https://uptowngoodfood.com/farmers-market" target="_blank" rel="noreferrer"> Farm Stand</a></h3>
                                        Every Thursday from June 6th to October 31st / 4pm to 7pm<br />
                                        330 St Nicholas Ave, New York, NY 10027</li>
                                </ul>
                                <p>
                                    <a href='https://uptowngoodfood.com/' target='_blank' rel="noreferrer">Market Site <FontAwesomeIcon icon={faArrowRightLong} /></a>
                                </p>
                            </Col>
                            <Col sm={12}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='fs-2'>1</Card.Title>
                                        <Card.Subtitle>Apply</Card.Subtitle>
                                        <Card.Text>Introducing our products to Harlem! Applying to become a wholesaler.</Card.Text>
                                        <Card.Link href='https://airtable.com/apptWMYTZ4msRriKR/pag1Brc077lvbgoEZ/form'>Application <FontAwesomeIcon icon={faArrowRightLong} /></Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='fs-2'>2</Card.Title>
                                        <Card.Subtitle>Appointment</Card.Subtitle>
                                        <Card.Text>Requesting a meeting to discuss and learn more about us. We look forward to connecting and exploring potential opportunities.</Card.Text>
                                        <Card.Link href='https://calendar.app.google/7TFjBpT153Bq9fsh8'>Calendar <FontAwesomeIcon icon={faArrowRightLong} /></Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Container>
        </>
    );
}

export default MarketApplication;
