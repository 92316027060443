import { Col, Container, Button, Row } from "react-bootstrap";
import Helmet from "../components/Helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter, faYelp } from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

function Programs() {

    return (
        <>
            <Helmet title={'Farmers Market and Farm Stand'}></Helmet>
            <Container fluid className="p-5 bg-body-tertiary">
                <Container className="p-md-5">
                    <h1 className="col-md-5">Our Programs</h1>
                    <p className="col-md-6">Connecting Communities through Fresh Food and Sustainable Initiatives</p>
                </Container>
            </Container>

            <Container fluid className="text-center p-md-5 bg-warning">
                <Container className="py-md-5">
                    <h2>Farmers Markets</h2>
                    <Container fluid>
                        <Row className="align-items-center justify-content-md-center">
                            <Col md={2}><img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/assets/partners/SNAP.png`} loading="lazy" alt="SNAP/EBT logo" /></Col>
                            <Col md={2}><img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/assets/partners/NYCHealthyDOHMH.png`} loading="lazy" alt="NYC Healthy (DOHMH) logo" /></Col>
                            <Col md={2}><img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/assets/partners/NYSAgricultureMarkets_sm.png`} loading="lazy" alt="NYS Agriculture Markets logo" /></Col>
                            <Col md={2}><img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/assets/partners/OTCNetwork.png`} loading="lazy" alt="OTC Network logo" /></Col>
                        </Row>
                    </Container>
                </Container>
            </Container>

            <Container fluid className="p-5 my-5 hero-banner bg-light">
                <Row className="align-items-center py-5 justify-content-center mx-auto">
                    <Col md={4}>
                        <h2 className="fw-normal lh-1">Uptown Good Food</h2>
                        <ul className="list-unstyled">
                            <li><h3><a href="https://uptowngoodfood.com/farmers-market" target="_blank" rel="noreferrer">Farmers Market</a></h3>
                                Every Saturday from June 1st to August 31st / 9am to 3pm<br />
                                St Nicholas Park, St Nicholas Ave St Nicholas Ave &amp;, W 137th St, New York, NY 10030</li>
                            <li><h3><a href="https://uptowngoodfood.com/csa-enrollment" target="_blank" rel="noreferrer">Farm Stand &amp; CSA</a></h3>
                                Every Thursdays from June 6th to October 31st / 4pm to 7pm<br />
                                St Nicholas Miracle Garden, 330 St Nicholas Ave, New York, NY 10027</li>
                        </ul>
                        <p>
                            <Button href="/farmersmarket/" size="lg" variant="success text-white me-3">Learn More</Button>
                            <Button href="https://uptowngoodfood.com/" size="lg" variant="outline-secondary">Market Site</Button>
                        </p>
                        <ul className="list-inline">
                            <li className="list-inline-item">Follow us:</li>
                            <li className="list-inline-item"><a href="https://www.facebook.com/uptowngoodfood/" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                            <li className="list-inline-item"><a href="https://www.instagram.com/uptowngoodfood" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
                            <li className="list-inline-item"><a href="https://twitter.com/uptowngoodfood" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a></li>
                            <li className="list-inline-item"><a href="https://www.yelp.com/biz/uptown-good-food-new-york" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faYelp} /></a></li>
                            <li className="list-inline-item"><a href="https://maps.app.goo.gl/PrktJd2K91i5En4s7" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faMapMarkerAlt} /></a></li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/assets/markets/ugf_fm.png`} loading="lazy" alt="Uptown Good Food Farmers Market" />
                    </Col>
                </Row>
                <Row className="align-items-center py-5 justify-content-center mx-auto">
                    <Col md={4}>
                        <img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/assets/markets/bxpe.png`} loading="lazy" alt="Bronx Park East Farmers Market" />
                    </Col>
                    <Col md={4}>
                        <h2 className="fw-normal lh-1">Bronx Park East (BxPE)</h2>
                        <ul className="list-unstyled">
                            <li><h3><a href="https://bxpemarket.com" target="_blank" rel="noreferrer">Farmers Market</a></h3>
                                Sundays, June 2nd - November 24th / 9am - 4pm<br />
                                Bronx Park / Brady Playground, 2045 Bronx Pk E, Bronx, NY 10462</li>
                        </ul>
                        <p>
                            <Button href="/farmersmarket/" size="lg" variant="success text-white me-3">Learn More</Button>
                            <Button href="https://bxpemarket.com/" size="lg" variant="outline-secondary">Market Site</Button>
                        </p>
                        <ul className="list-inline">
                            <li className="list-inline-item">Follow us:</li>
                            <li className="list-inline-item"><a href="https://www.facebook.com/bxpemarket/" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                            <li className="list-inline-item"><a href="https://www.instagram.com/bxpemarket" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
                            <li className="list-inline-item"><a href="https://twitter.com/bxpemarket" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a></li>
                            <li className="list-inline-item"><a href="https://www.yelp.com/biz/bronx-park-east-csa-new-york" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faYelp} /></a></li>
                            <li className="list-inline-item"><a href="https://g.page/ben-abrams-playground" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faMapMarkerAlt} /></a></li>
                        </ul>
                    </Col>
                </Row>
                <Row className="align-items-center py-5 justify-content-center mx-auto">
                    <Col md={4}>
                        <h2 className="fw-normal lh-1">White Plains Road</h2>
                        <ul className="list-unstyled">
                            <li><h3><a href="/farmersmarket/" target="_blank" rel="noreferrer">Farmers Market</a></h3>
                                Every Saturday from June 1st to August 31st / 9am to 3pm<br />
                                Williamsbridge Square, White Plains Road, E211 St &amp;, E 212 St, Bronx, NY</li>
                        </ul>
                        <p>
                            <Button href="/farmersmarket/" size="lg" variant="success text-white me-3">Learn More</Button>
                            <Button href="#" size="lg" variant="outline-secondary">Market Site</Button>
                        </p>
                        <ul className="list-inline">
                            <li className="list-inline-item">Follow us:</li>
                            <li className="list-inline-item"><a href="https://www.facebook.com/wprmarket/" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                            <li className="list-inline-item"><a href="https://www.instagram.com/wprmarket" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <img className="img-fluid w-100 h-100" src={`${process.env.PUBLIC_URL}/assets/markets/wprmarket.jpg`} loading="lazy" alt="White Plains Road Farmers Market" />
                    </Col>
                </Row>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="text-center p-0 pt-md-5">
                    <Container fluid className="p-5 bg-warning">
                        <h2>Community Supported Agriculture</h2>
                    </Container>

                    <Container fluid className="p-5 bg-white">
                        <p className="col-md-6 mb-5 mx-auto fs-4">Explore the abundance of fresh, seasonal produce with Community Supported Agriculture (CSA) programs in Harlem and The Bronx from June to October, fostering a connection between local farmers and the community.</p>
                        <p>
                            <Button href="/csa/" size="lg" variant="outline-secondary">Enroll Today</Button>
                        </p>
                        <img className="img-fluid col-md-5 p-5 rounded-3" src={`${process.env.PUBLIC_URL}/assets/markets/csa.png`} loading="lazy" alt="Community Supported Agriculture (CSA)" />
                    </Container>
                </Container>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="text-center p-0">
                    <Container fluid className="p-5 bg-warning">
                        <h2>Kids Club</h2>
                    </Container>
                </Container>

                <Container fluid>
                    <Row className="align-items-center">
                        <Col sm={12} md={6} className="p-md-5 fs-3">
                            <p>Join the Kids Club in Harlem and The Bronx from June to November for a fun and educational experience, offering children a unique opportunity to engage in seasonal activities, learn about healthy eating, and explore local farmers markets.</p>
                            <p><Button href="#" size="lg" variant="outline-secondary">Learn More</Button></p>
                        </Col>
                        <Col sm={12} md={6}>
                            <img className="img-fluid w-100 h-100" src={`${process.env.PUBLIC_URL}/assets/markets/youth.png`} loading="lazy" alt="Kids Club" />
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="text-center p-0">
                    <Container fluid className="p-5 bg-warning">
                        <h2>Bronx DOT Open Streets</h2>
                    </Container>
                </Container>

                <Container fluid>
                    <Row className="align-items-center">
                        <Col sm={12} md={6}>
                            <img className="img-fluid w-100 h-100" src={`${process.env.PUBLIC_URL}/assets/markets/openstreets.png`} loading="lazy" alt="Bronx Park East Farmers Market" />
                        </Col>
                        <Col sm={12} md={6} className="p-5 fs-3">
                            <p>Experience the community spirit and vibrant atmosphere of Bronx DOT Open Streets from June to August as local neighborhoods come alive with pedestrian-friendly spaces and engaging activities in The Bronx.</p>
                            <p><Button href="https://wprmarket.com/" target="_blank" size="lg" variant="outline-secondary">Learn More</Button></p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="text-center p-0">
                    <Container fluid className="p-5 bg-warning">
                        <h2>Harlem Greenhouse</h2>
                    </Container>
                </Container>

                <Container fluid>
                    <Row className="align-items-center">
                        <Col sm={12} md={6}>
                            <img className="img-fluid w-100 h-100" src={`${process.env.PUBLIC_URL}/assets/markets/greenhouse.jpg`} loading="lazy" alt="harlem Seed Starter Greenhouse" />
                        </Col>
                        <Col sm={12} md={6} className="p-5 fs-3">
                            <h3>Vision</h3>
                            <p>A greenhouse which impacts the growth of an urban and rural farm land using natural resources and technology.</p>
                            <h3>Mission</h3>
                            <p>To build a greenhouse in a community garden to serve as a model to learn and educate growers on the need for indoor and outdoor growing spaces using solar power, water management systems, municipal water system and rain catchers.</p>
                            <p><Button href="https://www.uptowngoodfood.com/greenhouse" target="_blank" rel="noreferrer" size="lg" variant="outline-secondary">Learn More</Button></p>
                        </Col>
                    </Row>
                </Container>
            </Container>

        </>
    );
}

export default Programs;
