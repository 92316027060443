import { Col, Container, Row } from "react-bootstrap";
import Helmet from "../components/Helmet";
import MovieClip from "../components/MovieClip";
import Team from "../components/Team";

function About() {
    return (
        <>
            <Helmet title={'About'}></Helmet>
            <Container className="py-5 my-5">
                <h2 className="mb-md-4">Who We Are</h2>
                <p>The Uptown &amp; Boogie Healthy Project aims to increase access to locally grown farm fresh food, promote micro-mobility, and reduce waste through composting and textile recycling in Harlem and The Bronx. Through the creation of the Kids Club and additional community engagement strategies, interactive sessions will be held at local farmers' markets to encourage good nutrition as part of a growing movement towards a healthier, better-fed, and more educated community.</p>
            </Container>

            <Container className="pb-5 my-5">
                <h2 className="mb-md-4">Our Core Values</h2>
                <p>Empowering communities in Upper Manhattan (Uptown) and The Bronx (Boogie), our mission is to enhance physical and mental well-being through nutritional programming while championing local producers and businesses and offering accessible micro-mobility education. Our vision is to cultivate healthier neighborhoods by expanding access to farm-fresh food and promoting micro-mobility for significantly improved community well-being.</p>
            </Container>

            <Container fluid className="bg-light">
                <Container className="p-5">
                    <Row className="align-items-center">
                        <Col sm={12} md={6}>
                            <h2>Meet Our Founder</h2>
                            <p>Judith "Judi" Desire, the founder of Uptown &amp; Boogie Healthy Project, is dedicated to providing crucial resources and social activities to promote physical and mental health in the Upper Manhattan and Bronx communities.</p>
                            <p>Judi Desire is a passionate advocate for sustainable living here in New York City, directly focusing on enhancing Harlem and the Bronx community neighborhoods. She launched her first initiative in 2018 after being awarded the Community-Scale Composting Grant by the Citizens Committee for New York City, which funded the construction of a 3-bin outdoor wooden compost system at William B. Washington Garden in Harlem. From 2018 to 2022, Judi further enriched this community garden by partnering with the NY Department of Sanitation and GrowNYC, a renowned non-profit providing essential sustainability services. Together, they collected over 1200 lbs of food scraps per week, processing 600 lbs in the garden's outdoor compost system. Today, the bin system is an overflow solution for neighboring drop-off sites.</p>
                            <p>In 2022, Judi initiated a food scrap drop-off at the Bronx Park East Farmers Market and she plans to establish a permanent drop-off site. She aims to advance Manhattan's sustainability, transform organic waste into compost and renewable energy, and promote ecological and community progress. Her efforts also included spearheading farmer's markets, linking local producers with urban consumers, and enhancing access to fresh produce.</p>
                        </Col>
                        <Col sm={12} md={6}>
                            <img className="img-fluid rounded" src={`${process.env.PUBLIC_URL}/assets/JUDIDESIRE.png`} loading="lazy" alt="Judi Desire" />
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* Meet our talented team Bubble */}
            <Team />

            <Container className="my-md-5">
                <h2 className="mb-3">Interviews</h2>
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <MovieClip vid="vcir3NSTCAM" />
                        </Col>
                        <Col sm={12} md={6}>
                            <h4>Uptown &amp; Boogie Healthy Project | OPEN</h4>
                            <p>OPEN Host Daren Jaime invites Judi Desire, the Executive Director and Farmers Market Manager of the Uptown & Boogie Healthy Project, for an enlightening conversation. They delve into how Uptown & Boogie's local farmers' markets bridge the gap between urban communities and neighborhood farmers. Join us to learn more about supporting local produce and fostering community ties.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <MovieClip vid="xGhhr8BqZEk" />
                        </Col>
                        <Col sm={12} md={6}>
                            <h4>Bronx Park East Farmers Market | OPEN</h4>
                            <p>OPEN Host Daren Jaime sits down with the Executive Director / Farmers Market Manager of the Uptown & Boogie Healthy Project, Judi Desire discusses the Bronx compost drop-off, the new farmers market location, White Plains Road Farmers Market, and the impact of local farmers markets on community wellness. </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

export default About;
