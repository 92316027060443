import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";

import Footer from './components/Footer';
import NavBarComponent from './components/NavBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing';
import About from './pages/About';
import FarmersMarket from './pages/FarmersMarket';
import Support from './pages/Support';
import Team from './pages/Team';
import Composting from './pages/Composting';

import NotFound from './pages/NotFound';
import TextileRecycling from './pages/TextileRecycling';
import YouthPrograms from './pages/YouthPrograms';
import MarketApplication from './pages/MarketApplication';
import Contact from './pages/Contact';
import Programs from './pages/Programs';
import FarmShareCSA from './pages/FarmShareCSA';
import Benefits from './pages/Benefits';
import JobOpportunities from './pages/JobOpportunities';
import BoardOpportunities from './pages/BoardOpportunities';
import ChefsRestaurants from './pages/ChefsRestaurants';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <header>
          <NavBarComponent />
        </header>
        <main>
          <Routes>
            <Route index element={<Landing />} />
            {['about', 'leadership', 'whoweare'].map((path) => (
              <Route key={path} path={path} element={<About />} />
            ))}
            {['career', 'jobs', 'hiring'].map((path) => (
              <Route key={path} path={path} element={<JobOpportunities />} />
            ))}
            {['board', 'boardmember', 'treasurer'].map((path) => (
              <Route key={path} path={path} element={<BoardOpportunities />} />
            ))}
            {['contact', 'contactus', '/contact/'].map((path) => (
              <Route key={path} path={path} element={<Contact />} />
            ))}
            {['overview', 'harvesthub', '/programs/'].map((path) => (
              <Route key={path} path={path} element={<Programs />} />
            ))}
            {['farming', 'farmersmarkets', '/farmersmarket/'].map((path) => (
              <Route key={path} path={path} element={<FarmersMarket />} />
            ))}
            {['cooking', 'chefs', '/restaurants/'].map((path) => (
              <Route key={path} path={path} element={<ChefsRestaurants />} />
            ))}
            {['snapebt', 'farmersmarketbenefits', '/benefits/'].map((path) => (
              <Route key={path} path={path} element={<Benefits />} />
            ))}
            {['apply', 'vendorsapply', '/vendors/'].map((path) => (
              <Route key={path} path={path} element={<MarketApplication />} />
            ))}
            {['youth', 'youthprograms', '/kidsclub/', '/kidszone/', '/popkidsclub/'].map((path) => (
              <Route key={path} path={path} element={<YouthPrograms />} />
            ))}
            {['farmshare', 'communitysupportedagriculture', '/csa/'].map((path) => (
              <Route key={path} path={path} element={<FarmShareCSA />} />
            ))}
            {['support', 'supportus', "/donate/"].map((path) => (
              <Route key={path} path={path} element={<Support />} />
            ))}
            {["/leadership/", "/team/", "/ourteam/"].map((path) => (
              <Route key={path} path={path} element={<Team />} />
            ))}
            {["/composting/", "/waste-reduction/"].map((path) => (
              <Route key={path} path={path} element={<Composting />} />
            ))}
            {["/textilerecycling/", "/textile-recycling/"].map((path) => (
              <Route key={path} path={path} element={<TextileRecycling />} />
            ))}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <footer className="my-5"><Footer /></footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
