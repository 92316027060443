import { Container, Button, Row, Col, Card } from "react-bootstrap";
import Helmet from "../components/Helmet";
import Markets from '../components/Markets';

function FarmShareCSA() {

    return (
        <>
            <Helmet title={'Community Supported Agriculture (CSA) | Farm Stand'}></Helmet>
            <Container fluid className="p-5 bg-body-tertiary">
                <Container className="text-center">
                    <h1>CSA</h1>
                    <p className="col-md-6 mb-5 mx-auto">Are you seeking a vibrant, community-driven CSA in Harlem and The Bronx? Look no further! Our CSA is your gateway to a bounty of fresh, locally sourced fruits and vegetables. Discover the difference between farm-to-table living and elevate your plate to new heights.</p>
                    <img className="img-fluid rounded-3" src={`${process.env.PUBLIC_URL}/assets/markets/csa.png`} loading="lazy" alt="Community Supported Agriculture (CSA)" />
                </Container>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="text-center p-0 pt-md-5">
                    <Container fluid className="p-5 bg-warning">
                        <h2>Locations</h2>
                    </Container>

                    <Container fluid className="p-5 bg-white">
                        <Markets />
                    </Container>
                </Container>
            </Container>


            <Container fluid className="p-0">
                <Container fluid className="p-0 pt-md-5">
                    <Container fluid className="p-5 text-center bg-warning">
                        <h2>Why Choose Our CSA?</h2>
                    </Container>

                    <Container className="p-5 bg-white text-center">
                        <Row className='g-5 justify-content-around'>
                            <Col sm={12} md={3}>
                                <h3>Seasonal Delights</h3>
                                <p>Immerse yourself in a culinary journey with our diverse seasonal produce, carefully curated for your enjoyment.</p>
                            </Col>
                            <Col sm={12} md={3}>
                                <h3>Locally Grown Goodness</h3>
                                <p>Support local farmers and enjoy your neighborhood's unmatched taste of fruits and vegetables.</p>
                            </Col>
                            <Col sm={12} md={3}>
                                <h3>Sustainability Matters</h3>
                                <p>Embrace a greener lifestyle by reducing your carbon footprint with our eco-friendly, locally sourced produce.</p>
                            </Col>
                            <Col sm={12} md={3}>
                                <h3>Direct Connection with Farmers</h3>
                                <p>Gain a personal connection with the hardworking farmers who cultivate the food you bring to your table.</p>
                            </Col>
                            <Col sm={12} md={3}>
                                <h3>Quality Assurance</h3>
                                <p>Our commitment to quality ensures that you receive only the best, handpicked produce each and every time.</p>
                            </Col>
                            <Col sm={12} md={3}>
                                <h3>Convenient Pickup Locations</h3>
                                <p>We have convenient pickup locations in Harlem and The Bronx, making it easy for you to access your share.</p>
                            </Col>
                            <Col sm={12} md={3}>
                                <h3>Flexible Memberships</h3>
                                <p>Choose a membership plan that suits your lifestyle, whether a seasonal commitment or a year-round experience.</p>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="p-0 pt-md-5">
                    <Container fluid className="p-5 text-center bg-warning">
                        <h2>How It Works</h2>
                    </Container>

                    <Container className="p-5 bg-white text-center">
                        <Row>
                            <Col sm={12} md={4}>
                                <p className='display-3'>1</p>
                                <h3>Sign Up</h3>
                                <p>Joining our CSA is easy! Sign up on our website and choose a share size that fits your needs.</p>
                            </Col>
                            <Col sm={12} md={4}>
                                <p className='display-3'>2</p>
                                <h3>Harvest Time</h3>
                                <p>Receive a weekly or bi-weekly share of the freshest produce from our partner farms.</p>
                            </Col>
                            <Col sm={12} md={4}>
                                <p className='display-3'>3</p>
                                <h3>Community Connection</h3>
                                <p>Engage with fellow CSA members, attend events, and be part of a thriving community centered around fresh, local food.</p>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="p-0 pt-md-5">
                    <Container fluid className="p-5 text-center bg-warning">
                        <h2>Shares</h2>
                    </Container>

                    <Container className="p-5 bg-white text-center">
                        <p className="lead">Experience our Community Supported Agriculture (CSA) program offering unbeatable prices for 22-weeks in Harlem and The Bronx, available on Thursdays, Saturdays, and Sundays from June to October.</p>
                        <Row>
                            <Col sm={12} md={4}>
                                <Card>
                                    <Card.Header as="h3">Vegetables</Card.Header>
                                    <Card.Body>
                                        <Card.Title className="display-5 pricing-card-title">$23<small class="text-body-secondary fw-light">/wk</small></Card.Title>
                                        <Card.Text>
                                            Get fresh, seasonal veggies with our convenient CSA share pickup service.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} md={4}>
                                <Card>
                                    <Card.Header as="h3">Fruits</Card.Header>
                                    <Card.Body>
                                        <Card.Title className="display-5 pricing-card-title">$12<small class="text-body-secondary fw-light">/wk</small></Card.Title>
                                        <Card.Text>
                                            Delight in the sweetness of freshly picked small fruits with our convenient weekly CSA share pickup service.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} md={4}>
                                <Card>
                                    <Card.Header as="h3">Eggs</Card.Header>
                                    <Card.Body>
                                        <Card.Title className="display-5 pricing-card-title">$6<small class="text-body-secondary fw-light">/wk</small></Card.Title>
                                        <Card.Text>
                                            Elevate your breakfast game with our pasture-raised eggs CSA share.                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Container>

            <Container fluid className="p-0">
                <Container fluid className="text-center p-0 pt-md-5">
                    <Container fluid className="p-5 bg-warning">
                        <p className="col-md-8 mb-5 mx-auto fs-2">Ready to embark on a journey of flavor and community?</p>
                        <Button href="https://airtable.com/appN3Tz0kQjNeRzb5/pag0NtvFmMDgkfYPU/form" size="lg" variant="outline-dark" target="_blank">Enroll Today</Button>
                    </Container>
                </Container>
            </Container>

        </>
    );
}

export default FarmShareCSA;
