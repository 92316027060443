import { Button, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import Helmet from "../components/Helmet";

function Team() {
    return (
        <>
            <Helmet title={'Our Board'}></Helmet>
            <Container fluid className="p-5 my-5 hero-banner text-center text-white">
                <h1>LEADERSHIP</h1>
            </Container>

            <Container className="my-md-5">
                <h2 className="position-relative">JUDI DESIRE - PRESIDENT<span className="position-absolute end-0 fs-5"><a href="http://linkedin.com/in/jdesire" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a></span></h2>
                <p>Judith "Judi" Desire, the founder of Uptown &amp; Boogie Healthy Project, is dedicated to providing crucial resources and social activities to promote physical and mental health in the Upper Manhattan and Bronx communities.</p>
                <p>As a native New Yorker, Judi grew up in the Caribbean community of Brooklyn, New York. After obtaining a B.S. in Computer Science from the New York Institute of Technology, she spent over 10+ years as a highly skilled and passionate Front-End Web Developer focusing on businesses and developing technologies. Throughout their career, Judi has worked with industry giants such as Northrop Grumman, Ogilvy, Conde Nast, National Broadcasting Company (NBC), Grey, R/GA, RAPP, AKQA, Ruder Finn Inc., Rumble Fox, Tommy Hilfiger, Droga5 and Apple, among others. In 2006, she moved to Harlem and discovered her passion for cycling. Learning to ride a bicycle as an adult in 2010, Judi began exploring her hometown and immersing herself in the vibrant cycling culture. Her enthusiasm for cycling led her to enroll in a bike mechanic course at the United Bicycle Institute Bicycle Mechanics School in Portland, Oregon. Inspired by her newfound love for biking, she embarked on solo bike tours across various countries from 2013 to 2017.</p>
                <p>Judi's dedication to promoting cycling extended beyond her pursuits, which led her to first volunteer for Bike New York as a bike instructor for their adult and kid learn-to-ride program. Impressed by her commitment and expertise, she transitioned into a paid role as a bike instructor, teaching children's after-school and camp programs and adult bicycle basics at various locations in the city.</p>
                <p>In September 2017, Judi's passion for cycling inspired her to establish Uptown & Boogie Bicycle Advocacy. Through this organization, she organized social group rides in Harlem and The Bronx, aiming to increase awareness of commuting and traveling throughout the city while promoting the health benefits of cycling. In June 2018, Judi obtained a League Cycling Instructors (LCIs) Certification from the League of American Bicyclists. The certification allowed her to instruct kids and adults on bike riding skills and lead social group rides, workshops, and street skills sessions in Harlem and the Bronx.</p>
                <p>Judi's commitment to community health expanded into the realm of food access. She graduated from the Farm School NYC Citywide Program and the GrowNYC Farmer Assistance Beginning Farmer Program, focusing on urban food access and supporting minority agricultural communities. She received New York City Department of Parks & Recreation park permits to open farmers markets in Harlem and Pelham Parkway. She ensured their USDA approval to accept various nutrition assistance programs like SNAP, FMNP, OTC, and Health Bucks. Additionally, Judi obtained Greenthumb's approval to host a farm stand program at a community garden in Harlem.</p>
                <p>Judi further initiated an agriculture mentorship program to support the farm programs and vendors at the markets, collaborating with Summer Youth Employment Program (SYEP) interns for six weeks. She also established a six-week POP Kids Club, catering to children between the ages of 4 and 13, to promote healthy eating habits. The club provided $2 tokens to purchase fruits and vegetables at the market.</p>
                <p>Judi's dedication to environmental sustainability and community engagement extended to creating eco-friendly green spaces in two Harlem community gardens. She completed the Snug Harbor Cultural Center & Botanical Garden Master Composting Program, acquiring the skills to build and maintain outdoor compost systems. Her efforts were recognized when she received the 2018 Community-Scale Composting Grant from the Citizens Committee for New York City. Under her leadership, the William B. Washington Garden in Harlem implemented a three-bin outdoor wooden compost system and facilitated a 24/7 community drop-off organics collection.</p>
                <p>In June 2021, the Bronx Park East Community Supported Agriculture initiative began as a pilot project in Bronx Park, striving to bridge the gap between local farms and communities in Pelham Parkway and Harlem, thereby championing nutritional equity. By 2022, our outreach broadened, introducing the Bronx Park East Farmers Market & CSA in The Bronx, and launching the Uptown Good Food Farm Stand in Harlem, graciously hosted by Saint Nicholas Miracle Garden. Fast forward to 2023, and we proudly unveiled the Uptown Good Food Farmers Market in Harlem. From June to November, our farm-centric programs prioritize community health and fresh, local produce.</p>
                <p>Judi Desire's extensive experience and passion for promoting urban food access, supporting minority agricultural communities, and establishing sustainable farm businesses in New York City make her a remarkable advocate for health and community well-being.</p>
                <p>In 2022, Judi Desire created a successful pilot of a four-week waste reduction program at Bronx Park East Farmers in October 2022. We are thrilled to announce our return for a 22-week initiative in partnership with GreenFeen OrganiX and Green Tree Textile Recycling this year. Our objective remains steadfast in bringing effective Waste Management Programs to The Bronx and fostering sustainable practices.</p>
                <p>In our continuous efforts to combat environmental hazards caused by landfills, we are proud to collaborate with GreenFeen OrganiX, a women-owned business based in The Bronx. Together, we will establish a food scrap drop-off site as a more sustainable alternative to landfill disposal.</p>
                <p>Working with GreenFeen OrganiX ensures food scrap collection and local processing within the Bronx community. This initiative allows us to divert organic waste from landfills and contribute to a cleaner and healthier environment.</p>
                <p>Judi Desire partnered with Green Tree Textile Recycling to further our commitment to environmental preservation. Their recycling services support individuals facing disadvantages and those in transitional periods. Green Tree aims to safeguard the environment by repurposing clothing and textiles for reuse by environmentally conscious manufacturers and designers.</p>
                <p>Through our collaboration with Green Tree Textile Recycling, we endeavor to extend the lifespan of clothing and reduce textile waste. This initiative aligns with our mission to promote sustainable practices and contribute to a circular economy.</p>
                <p>Together with our partners, GreenFeen OrganiX and Green Tree Textile Recycling, we impact The Bronx by implementing comprehensive waste reduction programs and encouraging sustainable behaviors.</p>
            </Container>

            <Container className="my-md-5">
                <h2 className="position-relative">KRISTIA M. BEAUBRUN - SECRETARY<span className="position-absolute end-0 fs-5"><a href="http://linkedin.com/in/kbeaubrun" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a></span></h2>
                <p>Kristia is a dual graduate of The City University of New York with a Bachelor of Arts in Journalism from Brooklyn College and a Master of Arts in Corporate Communication from Baruch College. She is a  wordsmith and communications professional for entrepreneurs, nonprofits, and faith-based community organizations who seek to elevate their brand and communication. </p>
                <p>She helped amplify community concerns, initiate conversations to identify viable solutions, raise awareness of opportunities, and promote civic engagement as a liaison to government agencies. </p>
                <p>She served in the U.S. House of Representatives. She advocated for constituents' assistance to resolve matters pending with federal government agencies after demonstrating her ability to communicate effectively with members, government and elected officials, and media professionals. </p>
                <p>To make a more significant impact in the borough of Brooklyn, Kristia transitioned from federal to City government. As a Director of Communications in the New York City Council, she used her previous skills to support constituent services while managing intergovernmental, public, and media relations. </p>
            </Container>

            <Container className="my-md-5">
                <h2 className="position-relative">CELESTE BEATTY - TREASURER<span className="position-absolute end-0 fs-5"><a href="https://www.instagram.com/harlembrewery/" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></span></h2>
                <p>Celeste Beatty is the Brewer and Founder of Harlem Brewing Company and Harlem Brew South. By following her truth - she calls it Brewing it Forward. Celeste tapped into her passion for creating beers that infuse flavor, love, and pride. She has used her influence to help open doors for a new generation of brewers and indie beer distributors. Celeste launched Harlem Brewing Company in 2000, inspired by her love of the craft and the Harlem community. She became the first African American woman brewer to launch a commercially distributed beer. She later sold her beers in Walmart, the world's largest food and beverage company. </p>
                <p>In 2018, she co-launched the Rocky Mount Brewery, a collaboration project between Harlem Brew South and Spaceway Brewing in Rocky Mount, NC. She addressed the challenges she sees with beer deserts (areas that lack opportunities in craft brewing). Harlem Brew South is renovating a historic tobacco warehouse to house its first taproom and teaching brewery. Her distributed throughout NYC, along the east coast, as well as in Japan and the U.K. Celeste and her beers have received many awards, including Entrepreneur of the Year from the Harlem Business Alliance, Best Brew NYC, Best Amber Ale - Beer and Bacon Fest, Emerging Entrepreneur Award - Clinton Foundation and the Local Hero Award from the Association of Resident Theaters.</p>
                <p>Celeste has garnered international media coverage with features on TripAdvisor, Reuters, The Root, NBC, Fox News, MSNBC, Forbes, and People Mag. When she's not testing out new homebrew recipes, you might find her in a garden training up hops or sharing a tasty Harlem brew! Stay tuned for her first book, released by Quarto and published in 2023.</p>
            </Container>

            <Container fluid className="bg-light text-center py-5">
                <h3>Join the Team</h3>
                <Button href="mailto:ubhp@ubhealthyproject.org" target="_blank" size="lg" variant="outline-secondary">Application</Button>
            </Container>
        </>
    );
}

export default Team;
