import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';

const Team = () => {

    return (
        <>
            <Container fluid className="text-center my-md-5 py-md-5">
                <Container className="col-md-6 p-md-5">
                    <h2>Meet our talented team</h2>
                    <p>Discover the dedicated expertise of our talented team at Uptown &amp; Boogie Healthy Project, a leading agriculture and environmental nonprofit committed to sustainable solutions and positive impact.</p>
                </Container>
                <Container>
                    <Row>
                        <Col sm={12} md={4}>
                            <img src={`${process.env.PUBLIC_URL}/assets/team/JudiDesire.png`} loading="lazy" alt="Judi Desire" class="rounded-circle mx-auto mb-3" />
                            <p className="fs-5"><b>Judi Desire</b><br /> <small>President &amp; Founder</small></p>
                        </Col>
                        <Col sm={12} md={4}>
                            <img src={`${process.env.PUBLIC_URL}/assets/team/KristiaBeaubrun.png`} loading="lazy" alt="Kristia Beaubrun" class="rounded-circle mx-auto mb-3" />
                            <p className="fs-5"><b>Kristia M. Beaubrun </b><br /> <small>Secretary</small></p>
                        </Col>
                        <Col sm={12} md={4}>
                            <img src={`${process.env.PUBLIC_URL}/assets/team/CelesteBeatty.png`} loading="lazy" alt="Celeste Beatty" class="rounded-circle mx-auto mb-3" />
                            <p className="fs-5"><b>Celeste Beatty</b><br /> <small>Treasurer</small></p>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='my-4 bg-light py-3'>
                    <h3>Do you have a passion for local agriculture?</h3>
                </Container>
                <Container>
                    <p className='col-md-6 mx-auto'>The Uptown &amp; Boogie Healthy Project seeks a dedicated and detail-oriented individuals to join our Board of Directors. We are a non-profit organization dedicated to supporting local farmers by providing opportunities for them to sell their products directly to consumers through farmers' markets, farm stands, and wholesale channels.</p>
                    <p>
                        <Button href="/board/" target="_blank" size="lg" variant="warning text-dark me-3">Learn More</Button>
                    </p>
                </Container>
            </Container>
        </>
    )
}

export default Team