import React from 'react';
import { Table, Button, Container, Modal } from 'react-bootstrap';

function Guideline(props) {

    return (
        <Modal {...props} aria-labelledby="contained-modal-guideline-vcenter">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-guideline-vcenter">
                    <p className='text-center'>NYS License and Permit Guidelines</p>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="grid-example">
                <p className='text-center'>Please expect a processing period ranging from 30 to 90 business days to complete permits and paperwork.</p>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Application, Permit or License</th>
                            <th>Who Needs It</th>
                            <th>Online</th>
                        </tr>
                        <tr>
                            <td>NYS Dep. of Taxation and Finance Certificate of Authority</td>
                            <td>Everyone</td>
                            <td>https://aca.licensecenter.ny.gov/ACA/Default.aspx <br />
                                <a rel="noopener noreferrer" target="_blank" href="https://www.tax.ny.gov/pdf/current_forms/st/dtf17i.pdf http://www.tax.ny.gov/bus/st/stidx.htm"  >https://www.tax.ny.gov/pdf/current_forms/st/dtf17i.pdf http://www.tax.ny.gov/bus/st/stidx.htm</a></td>
                        </tr>
                        <tr>
                            <td>Temporary Street Vendors Permit</td>
                            <td>Anyone Selling</td>
                            <td><a rel="noopener noreferrer" target="_blank" href="http://www1.nyc.gov/site/dca/businesses/license-checklist-temporary-street-fair-vendor.page"  >http://www1.nyc.gov/site/dca/businesses/license-checklist-temporary-street-fair-vendor.page</a></td>
                        </tr>
                        <tr>
                            <td>Temporary Food Service Establishment Permit</td>
                            <td>Anyone Selling Food</td>
                            <td>https://www1.nyc.gov/nycbusiness/description/temporary-food-service-establishment-permit <br />
                                <a rel="noopener noreferrer" target="_blank" href="https://www.nyc.gov/assets/doh/downloads/pdf/environmental/fse-application-packet.pdf"  >https://www.nyc.gov/assets/doh/downloads/pdf/environmental/fse-application-packet.pdf</a></td>
                        </tr>
                        <tr>
                            <td>Food Handlers Permit (Food Protection)</td>
                            <td>Anyone Handling Food or Produce</td>
                            <td><a rel="noopener noreferrer" target="_blank" href="https://www.nyc.gov/site/doh/business/health-academy/food-protection-online-free.page"  >https://www.nyc.gov/site/doh/business/health-academy/food-protection-online-free.page</a></td>
                        </tr>
                        <tr>
                            <td>Home Processor's Exemption</td>
                            <td>Small Batch/Canned Foods or Baked Goods</td>
                            <td><a rel="noopener noreferrer" target="_blank" href="https://agriculture.ny.gov/food-safety/home-processing"  >https://agriculture.ny.gov/food-safety/home-processing</a></td>
                        </tr>
                        <tr>
                            <td>Mobile Vendors License</td>
                            <td>Food Trucks</td>
                            <td><a rel="noopener noreferrer" target="_blank" href="https://nyc-business.nyc.gov/nycbusiness/description/mobile-food-vending-license"  >https://nyc-business.nyc.gov/nycbusiness/description/mobile-food-vending-license</a></td>
                        </tr>
                        <tr>
                            <td>Insurance</td>
                            <td>Business Owners</td>
                            <td>Individual vendors are recommended to secure public and product liability insurance for their protection.</td>
                        </tr>
                        <tr>
                            <td>Workers Compensation Exempt Form</td>
                            <td>Business without Employees</td>
                            <td><a rel="noopener noreferrer" target="_blank" href="https://www.wcb.ny.gov/content/ebiz/wc_db_exemptions/requestExemptionOverview.jsp"  >https://www.wcb.ny.gov/content/ebiz/wc_db_exemptions/requestExemptionOverview.jsp</a> <br />
                                <a rel="noopener noreferrer" target="_blank" href="http://www.wcb.ny.gov/content/ebiz/wc_db_exemptions/requestExemptionOverview.jsp"  >http://www.wcb.ny.gov/content/ebiz/wc_db_exemptions/requestExemptionOverview.jsp</a></td>
                        </tr>
                        <tr>
                            <td>Farmers' Market Nutrition Program (WIC + Senior Checks)</td>
                            <td>Farmers, Urban Farmers, Gardeners with the intent to Sell</td>
                            <td><a rel="noopener noreferrer" target="_blank" href="https://agriculture.ny.gov/farming/selling-products-farmers-market"  >https://agriculture.ny.gov/farming/selling-products-farmers-market</a></td>
                        </tr>
                        <tr>
                            <td>Licensing</td>
                            <td>New York State Nursery Growers and Greenhouses</td>
                            <td><a rel="noopener noreferrer" target="_blank" href="https://agriculture.ny.gov/plant-industry/licensing-and-plant-inspections"  >https://agriculture.ny.gov/plant-industry/licensing-and-plant-inspections</a></td>
                        </tr>
                        <tr>
                            <td>Cornell Small Farms</td>
                            <td>Chickens, Poultry, and Other Meats</td>
                            <td><a rel="noopener noreferrer" target="_blank" href="https://smallfarms.cornell.edu/resources/guides/"  >https://smallfarms.cornell.edu/resources/guides/</a></td>
                        </tr>
                    </thead>
                </Table>
                <Container>

                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
export default Guideline;